
import { defineComponent } from 'vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  name: 'Back 2 Home',
  components: {
    SvgIcon,
  },
});
