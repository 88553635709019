
import { defineComponent } from 'vue';

import Back2Home from '../components/Back2Home.vue';

export default defineComponent({
  name: 'Impressum',
  components: {
    Back2Home,
  },
});
