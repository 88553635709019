<template>
  <router-link class="back2home" to="/">
    <a @click="navigate" href="/">
      <svg-icon class="icon--small" icon="arrow" fill="#333" />
      <span>Zurück zur Startseite</span>
    </a>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  name: 'Back 2 Home',
  components: {
    SvgIcon,
  },
});
</script>

<style lang="scss">
.back2home {
  opacity: 0.67;
  transition: all 125ms ease-in-out;

  display: block;

  @include fluid-type($spacingRegular, $spacingBig, padding-top);
  @include fluid-type($spacingRegular, $spacingBig, padding-bottom);

  a {
    display: flex;
    align-items: center;
  }
  .icon {
    transform: rotate(180deg);

    @include fluid-type($spacingDefault, $spacingDefault * 1.5, margin-right);
  }

  @include onHover() {
    opacity: 1;
  }
}
</style>
