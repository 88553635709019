<template>
  <div class="impressum">
    <back-2-home></back-2-home>
    <h1>Impressum</h1>
    <h2>Kontaktadresse</h2>
    <p>
      Merki BauDienstleistungen GmbH<br />
      Katzenbachstrasse 90<br />
      8052 Zürich<br />
      Schweiz<br />
      merki@merki-bd.ch<br />
      +41 79 444 00 92
    </p>

    <h2>Vertretungsberechtigte Personen</h2>
    <p>Markus Merki, Geschäftsführer</p>

    <h2>Handelsregistereintrag</h2>
    <p>
      Eingetragener Firmenname: Merki BauDienstleistungen GmbH<br />
      Nummer: CH-020.4.068.380-4
    </p>

    <h2>Haftungsausschluss</h2>
    <p>
      Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit,
      Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen. Haftungsansprüche gegen den
      Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der
      Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung
      oder durch technische Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind
      unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte
      Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
      Veröffentlichung zeitweise oder endgültig einzustellen.
    </p>

    <h2>Haftung für Links</h2>
    <p>
      Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs Es
      wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung
      solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
    </p>

    <h2>Urheberrechte</h2>
    <p>
      Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der
      Website gehören ausschliesslich der Firma Merki BauDienstleistungen GmbH oder den speziell
      genannten Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche
      Zustimmung der Urheberrechtsträger im Voraus einzuholen.
    </p>

    <h2>Quelle</h2>
    <p>
      Dieses Impressum wurde am 13.01.2021 mit dem Impressum Generator der Webdesign Agentur
      <a href="https://webkoenig.ch/" target="_blank">Webkönig</a> erstellt. Die Agentur Webkönig
      übernimmt keine Haftung.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Back2Home from '../components/Back2Home.vue';

export default defineComponent({
  name: 'Impressum',
  components: {
    Back2Home,
  },
});
</script>
